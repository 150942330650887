<template>
  <b-card>
    <!-- about -->
    <h3>HELPFUL TIPS</h3>
    <div
      class="mt-2"
    >
      <b-list-group flush>
        <b-list-group-item>To save time copy and paste the patient information from the left into booking form</b-list-group-item>
        <b-list-group-item>Remember to select correct tiimezone/city if in Perth, Adelaide, Darwin, Brisbane,etc.</b-list-group-item>
        <b-list-group-item>Currently set at GMT + 10:00 Melbourne time</b-list-group-item>
      </b-list-group>
    </div>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
