/* eslint-disable vue/no-parsing-error */ /* eslint-disable vue/no-parsing-error
*/
<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <logo />
          <h2 class="brand-text text-primary ml-1">
            Care Programs
          </h2>
          <h2 class="brand-text text-primary ml-1">
            Booking Form
          </h2>
        </b-link>
      </b-row>
    </div>
    <!-- /Brand logo-->
    <div class="p-4">
      <div id="patient-details">
        <section id="profile-info">
          <b-row>
            <b-row>
              <!-- about suggested page and twitter feed -->
              <b-col
                lg="3"
                cols="12"
                order="2"
                order-lg="1"
              >
                <patient-info :about-data="patientData" />
                <booking-info-help-text-vue />
              </b-col>
              <b-col
                lg="9"
                cols="12"
                order="1"
                order-lg="2"
              >
                <vue-friendly-iframe
                  :src="bookingURL"
                  :name="bookFrame"
                  @load="onLoadIframe"
                />
              </b-col>
            </b-row>
          </b-row>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import Logo from '@core/layouts/components/Logo.vue'
import PatientInfo from '@/views/apps/user/patient-data-view/PatientInfoSmall.vue'
import BookingInfoHelpTextVue from './BookingInfoHelpText.vue'

function findIframeByName(name) {
  // return _.find(window.frames, frame => frame.name === name)
  document
    .querySelector('book_frame')
    .contentWindow.window.document.querySelector(
      '#booking_patient_first_name',
    ).value = name
}

export default {
  components: {
    BRow,
    BCol,
    PatientInfo,
    BookingInfoHelpTextVue,
    Logo,
  },
  data() {
    return {
      bookFrame: 'book_frame',
      patientData: null,
      bookingURL: 'https://careprograms.au2.cliniko.com/bookings',
    }
  },
  created() {
    this.getPatientDetails()
  },
  methods: {
    getPatientDetails() {
      store
        .dispatch('userStore/getPatientPublicDetails', {
          userId: router.currentRoute.params.id,
          pharmacyId: router.currentRoute.params.pid,
        })
        .then(response => {
          this.patientData = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.patientData = undefined
          }
        })
    },
    onLoadIframe() {
      // eslint-disable-next-line no-undef
      findIframeByName(this.patientData.fname)
      // const iframe = findIframeByName(this.patientData.fname)
      // iframe.document.getElementById(
      //   'booking_patient_first_name',
      // ).value = this.patientData.fname
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style>
.vue-friendly-iframe iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 90vh; /* Viewport-relative units */
  width: 80vw;
}
.auth-wrapper.auth-v2 .auth-inner {
    height: 12vh;
    overflow-y: auto;
}
.auth-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 10vh;
}
</style>
